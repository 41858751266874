import { HttpError, useCan, useNavigation, useShow, useTranslate } from "@pankod/refine-core";
import { Parent } from "./type";
import { Avatar, Button, Card, CreateButton, DataGrid, DeleteButton, Grid, GridColumns, List, Paper, Stack, Typography, useDataGrid } from "@pankod/refine-mui";
import { UserInfoText } from "pages/student";
import { CalendarMonthOutlined, EditOutlined, Email, Phone, SchoolOutlined } from "@mui/icons-material";
import { DurationDate } from "components";
import React from "react";
import { StudentType } from "utils/type";
import UserAvatar from "components/custom";
import moment from "moment";


export const ParentShow = () => {
    const { queryResult, showId } = useShow<Parent>({
        resource: "parent",
    });
    const parent = queryResult.data?.data;
    const { show, edit, push, createUrl } = useNavigation();
    const { data: canEditParent } = useCan({
        resource: "parent",
        action: "edit",
    });
    const { data: canDeleteParent } = useCan({
        resource: "parent",
        action: "delete",
    });

    const { dataGridProps } = useDataGrid<Parent, HttpError, Parent>({
        resource: "student",
        permanentFilter: [
            {
                field: "parent",
                operator: "eq",
                value: parent?.id,
            },
        ],
        initialPageSize: 4,
        queryOptions: {
            enabled: parent !== undefined,
        },
        syncWithLocation: false,
        initialSorter: [
            {
                field: "last_active",
                order: "desc",
            },
        ],
    });
    const t = useTranslate();
    const columns = React.useMemo<GridColumns<StudentType>>(
        () => [
            {
                field: "firstName",
                headerName: "Full Name",
                minWidth: 250,
                flex: 1,
                renderCell(params) {
                    const principal = params.row;
                    return <UserAvatar user={principal as any} />;
                },
            },
            {
                field: "progress_lessonId",
                headerName: "Fluency Progress",
                minWidth: 120,
                flex: 1,
                valueGetter(params) {
                    const progresses = (params.row as any).progress_lessonId;
                    return progresses;
                },
                filterable: true,
                renderCell(params) {
                    const progress = params.value;
                    const pretest = (params.row as any).introductionPresented === 0 ? false : true;
                    return (
                        <Typography>
                            <b>
                                {progress <= 0
                                    ? pretest ? "Pre Test" : "Not Started"
                                    : progress >= 21
                                        ? "Completed"
                                        : `Lesson ${progress}`}
                            </b>
                        </Typography>
                    );
                },
            },
            {
                field: "ctProgressItemNumber",
                headerName: "CT Progress",
                minWidth: 120,
                flex: 1,
                renderCell(params) {
                    // const license = params.row.license;
                    // const expiresOn = moment(license.expiresOn);
                    // const createdOn = moment(license.created_at);
                    const progress = (params.row as any).ctProgressItemNumber ?? 0;
                    return progress >= 240 && (params.row as any).isComplete ? (
                        <b>Complete</b>
                    ) : progress ? (
                        <span>
                            Lesson <b>{Math.ceil((progress - 4) / 4) + 1}</b>
                            <br />
                            Activity <b>{((progress - 1) % 4) + 1}</b>
                        </span>
                    ) : (
                        <b>Not Started</b>
                    );
                },
            },
            {
                field: "last_active",
                headerName: "Last Active",
                flex: 1,
                minWidth: 150,
                renderCell(params) {
                    var diffTime = moment(params.row.last_active).fromNow();

                    // var lastLesson =
                    //   progresses
                    //     .sort((a, b) => b.last_active - a.last_active)
                    //     .find(
                    //       (a) => moment(new Date()).diff(a.last_active, "minute") < 1
                    //     ) || null;

                    return (
                        <Typography variant="body1">
                            {diffTime}
                            {/* {lastLesson && (
                    <>
                      <br />
                      on{" "}
                      <b>
                        {lastLesson.lessonId > 0 ? (
                          <>
                            Lesson <b>{lastLesson.lessonId}</b>
                          </>
                        ) : (
                          <b>Pre-Test</b>
                        )}
                      </b>
                    </>
                  )} */}
                        </Typography>
                    );
                },
            },
            {
                field: "created_at",
                headerName: "Created On",
                flex: 1,
                minWidth: 200,
                valueFormatter(v) {
                    const dt = moment(v.value);
                    return dt.format("L") + ` (${dt.fromNow()})`;
                },
            },

        ],
        [t]
    );
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={3}>
                <Paper sx={{ p: 2, paddingX: { xs: 4, md: 2 } }}>
                    <Stack alignItems="center" spacing={1}>
                        <div className="flex items-center gap-2">
                            <Phone fontSize="small" />
                            {parent?.email}
                        </div>
                        <Avatar src={parent?.photoURL} sx={{ width: 120, height: 120 }} />
                        <Typography variant="h6">
                            {parent?.firstName} {parent?.lastName}
                        </Typography>

                        <div className="flex items-center gap-2">
                            <Email fontSize="small" />
                            {parent?.email}
                        </div>
                        <div className="flex items-center gap-2">
                            <SchoolOutlined fontSize="small" />
                            {parent?.school?.code}
                        </div>
                    </Stack>
                    <br />
                    <Stack spacing={1}>
                        <UserInfoText>
                            <CalendarMonthOutlined />
                            <Typography variant="body1">
                                <DurationDate date={parent?.created_at} />
                            </Typography>
                        </UserInfoText>
                    </Stack>
                    <br />
                    <Stack
                        sx={{ mt: 2 }}
                        spacing={1}
                        direction={"row"}
                        justifyContent="space-between"
                    >
                        {canEditParent?.can && (
                            <Button
                                startIcon={<EditOutlined />}
                                onClick={() => {
                                    edit("user", showId!);
                                }}
                            >
                                Edit
                            </Button>
                        )}

                        {canDeleteParent?.can && (
                            <DeleteButton
                                onSuccess={() => {
                                    push("parent");
                                }}
                                recordItemId={showId}
                            />
                        )}
                    </Stack>
                </Paper>
            </Grid>
            <Grid item xs={12} lg={9}>
                <Stack direction="column" spacing={2}>
                    <List
                        resource="parent"
                        cardHeaderProps={{
                            title: "Students",
                            action: (
                                <CreateButton
                                    onClick={() => {
                                        push(
                                            createUrl("student") + `?parentId=${parent?.id}`
                                        );
                                    }}
                                />
                            ),
                        }}
                        cardProps={{ sx: { paddingX: { xs: 2, md: 0 } } }}
                    >
                        <DataGrid
                            {...dataGridProps}
                            columns={columns}
                            autoHeight
                            onCellClick={(cell) => {
                                show("student", cell.row.id);
                            }}
                            rowsPerPageOptions={[4, 10, 20, 100]}
                        />
                    </List>
                </Stack>
            </Grid>
        </Grid>
    )
}